import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { FaFacebook } from "react-icons/fa"
import { FiInstagram } from "react-icons/fi"

import ArdrakLogoCinza from "../../images/ardrak-logo-cinza.svg"

import colors from "../../styles/colors"
import { Container } from "../../styles/global"

const Footer = () => (
  <FooterSection>
    <MenuContainer>
      <StyledContainer>
        <TopMobile>
          <Link to="/">
            <img src={ArdrakLogoCinza} alt="Ardrak" />
          </Link>
          <SocialMobile>
            <p>Siga:</p>
            <a
              href="https://web.facebook.com/ardrak.oficial"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook size={27} />
            </a>
            <a
              href="https://www.instagram.com/ardrak.oficial"
              target="_blank"
              rel="noreferrer"
            >
              <FiInstagram size={27} />
            </a>
          </SocialMobile>
        </TopMobile>
        <Menu>
          <Links>
            <Link to="/produtos">Produtos</Link>
            <Link to="/empresa">Empresa</Link>
            <a
              href="https://munddi.com/ardrak/insta"
              target="_blank"
              rel="noreferrer"
            >
              Onde encontrar
            </a>
            {/* <Link to="/blog">Blog</Link> */}
            <Link to="/saiba-mais">Saiba mais</Link>
            <Link to="/contato">Contato</Link>
          </Links>
          <Social>
            <p>Siga:</p>
            <a
              href="https://web.facebook.com/ardrak.oficial"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook size={27} />
            </a>
            <a
              href="https://www.instagram.com/ardrak.oficial"
              target="_blank"
              rel="noreferrer"
            >
              <FiInstagram size={27} />
            </a>
          </Social>
        </Menu>
      </StyledContainer>
    </MenuContainer>
    <StyledContainer>
      <Copyright>
        <div>
          <p>
            Copyright © 2020 Ardrak. Todos os Direitos reservados. Projetado e
            desenvolvido por{" "}
            <a href="https://mribeiro.com.br" target="_blank" rel="noreferrer">
              MRibeiro
            </a>
            .
          </p>
          <p>
            CNPJ n.º 86.786.019/0001-20 <br />
            Alameda Cel. Eugênio Jardim, qd. 262, lt. 19, nº 225
            <br />
            Setor Marista, Goiânia - Goiás, CEP 74175-100
          </p>
        </div>
        <Link to="/">
          <img src={ArdrakLogoCinza} alt="Ardrak" />
        </Link>
      </Copyright>
    </StyledContainer>
  </FooterSection>
)

const StyledContainer = styled(Container)`
  @media (max-width: 1200px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const FooterSection = styled.section`
  background-color: ${colors.CINZA_CLARO};
`

const TopMobile = styled.div`
  display: none;

  @media (max-width: 840px) {
    padding-top: 45px;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 320px) {
    img {
      width: 140px;
    }

    font-size: 16px;
  }
`

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 0px 10px 0px;

  @media (max-width: 840px) {
    padding: 45px 0;
  }
`

const Links = styled.div`
  a {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    font-weight: 500;
    color: black;

    & + a {
      margin-left: 35px;
    }
  }
  a:hover {
    text-decoration: underline;
  }

  @media (max-width: 840px) {
    display: flex;
    flex-direction: column;

    a {
      padding-bottom: 15px;

      & + a {
        margin-left: 0px;
      }
    }
  }
`

const Social = styled.div`
  display: flex;
  align-items: center;
  a {
    color: black;
    padding-left: 12px;
  }
  a:hover {
    color: ${colors.VERDE_ESCURO};
  }

  @media (max-width: 840px) {
    display: none;
  }
`

const SocialMobile = styled(Social)`
  display: none;

  @media (max-width: 840px) {
    display: flex;
    align-items: center;
    a {
      color: black;
      padding-left: 12px;
    }
    a:hover {
      color: ${colors.VERDE_ESCURO};
    }
  }
`

const Copyright = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 33px 0;

  a {
    color: ${colors.CINZA_ESCURO};
    text-decoration: none;

    &:hover {
      color: ${colors.VERDE};
    }
  }

  div {
    max-width: 900px;
    color: ${colors.CINZA_ESCURO};
    padding-right: 20px;

    p {
      font-size: 18px;
      line-height: 30px;
      padding-bottom: 10px;
    }
  }

  @media (max-width: 840px) {
    padding: 0;
    img {
      display: none;
    }
    div {
      padding-right: 0;
      p {
        padding-bottom: 20px;
      }
    }
  }
`

const MenuContainer = styled.div`
  border-bottom: 1px solid #cecece;
  border-top: 1px solid #cecece;

  @media (max-width: 840px) {
    border-bottom: none;
  }
`

export default Footer
