import React, { useState } from "react"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "styled-components"

import { Container } from "../styles/global"
import colors from "../styles/colors"

import ardrakIcon from "../images/ardrak-horizontal.svg"

import { FiArrowUpRight } from "react-icons/fi"
import { AiOutlineMenu } from "react-icons/ai"
import { MdClose } from "react-icons/md"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <Nav>
      <ContainerDesktop>
        <MenuLinks>
          <AniLink cover direction="right" bg="#185729" to="/">
            <img src={ardrakIcon} alt="Ardrak" />
          </AniLink>
          <AniLink cover direction="right" bg="#185729" to="/produtos">
            Produtos
          </AniLink>
          <AniLink cover direction="right" bg="#185729" to="/empresa">
            Empresa
          </AniLink>
          <a
            href="https://munddi.com/ardrak/insta"
            target="_blank"
            rel="noreferrer"
          >
            Onde encontrar <FiArrowUpRight size={18} />
          </a>
          <AniLink cover direction="right" bg="#185729" to="/contato">
            Contato
          </AniLink>
          {/* <a href="https://blog.ardrak.com.br">Blog</a> */}
        </MenuLinks>
        <div>
          <AniLink cover direction="right" bg="#185729" to="/seja-parceiro">
            Seja parceiro
          </AniLink>
        </div>
      </ContainerDesktop>
      <ContainerMobile>
        <div className="blank" />
        <AniLink cover direction="right" bg="#2f3967" to="/">
          <img src={ardrakIcon} alt="Ardrak" />
        </AniLink>
        <button type="button" onClick={() => setMenuOpen(true)}>
          <AiOutlineMenu size={35} />
        </button>
      </ContainerMobile>
      <MenuMobile
        className="menu-mobile"
        style={{
          display: menuOpen ? "flex" : "none",
          opacity: menuOpen ? "1" : "0",
        }}
      >
        <button type="button" onClick={() => setMenuOpen(false)}>
          <MdClose size={45} />
        </button>
        <div>
          <AniLink fade to="/produtos">
            Produtos
          </AniLink>
          <AniLink fade to="/empresa">
            Empresa
          </AniLink>
          <a
            href="https://munddi.com/ardrak/insta"
            target="_blank"
            rel="noreferrer"
          >
            Onde Encontrar <FiArrowUpRight size={18} />
          </a>
          <AniLink cover direction="right" bg="#185729" to="/contato">
            Contato
          </AniLink>
          {/* <a href="https://blog.ardrak.com.br/">Blog</a> */}
        </div>
      </MenuMobile>
    </Nav>
  )
}

const Nav = styled.header`
  background: white;
  color: ${colors.VERDE_ESCURO};

  && > div {
    margin: 0 auto;
    max-width: 960;
  }
  a {
    text-decoration: none;
  }
`
const ContainerDesktop = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 0;

  a {
    display: flex;
    align-items: center;

    color: ${colors.VERDE_ESCURO};
    font-size: 20px;
    font-weight: 500;

    &:hover {
      color: ${colors.VERDE};
    }

    & + a {
      margin-left: 50px;
    }
  }

  @media (max-width: 940px) {
    display: none;
  }
`

const MenuLinks = styled.div`
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;

    svg {
      margin-bottom: 4px;
      margin-left: 4px;
    }
  }
`

const ContainerMobile = styled.div`
  display: none;

  @media (max-width: 940px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 95px;
    padding: 0 20px;

    div.blank {
      width: 35px;
    }
    img {
      height: 50px;
      padding: 0;
    }
    button {
      display: flex;
      align-items: center;
      padding: 0;
      padding-bottom: 6px;

      border: none;
      background: none;
      height: 35px;
      color: ${colors.VERDE_ESCURO};
    }
  }

  @media (max-width: 320px) {
    img {
      height: 40px;
    }
  }
`

const MenuMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  padding: 25px 25px 15% 20px;

  height: 100vh;
  width: 100vw;

  position: fixed;
  left: 0;
  top: 0;

  z-index: 99999;

  transition: opacity 0.7s linear 1s;
  opacity: 0;

  background-color: ${colors.VERDE_ESCURO};

  button {
    display: flex;
    align-items: center;
    padding: 0;

    border: none;
    background: none;
    height: 40px;
    width: auto;
    color: white;
    svg {
      position: absolute;
      right: 14px;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    a {
      display: block;
      color: white;
      font-size: 33px;
      text-transform: uppercase;
      margin-top: 24px;
      text-align: end;
    }
  }
  @media (max-width: 340px) {
    div {
      a {
        font-size: 30px;
      }
    }
  }
`

export default Header
